import { loadRemoteEntry } from '@angular-architects/module-federation';

fetch('assets/config/config.json').then(response => {
  return response.json()
})
.then(data => {  

  const baseUrl = !!data.production ? data.remoteEntryUrl: data.remoteEntryUrl_dev;
  // const masterdataRemoteEntryUrl = `${data.remoteEntryUrl}/iot-masterdata`;
  const chartsRemoteEntryUrl = `${baseUrl}/iot-charts`;
  const eventRemoteEntryUrl = `${baseUrl}/iot-event`;
  
  Promise.all([
    loadRemoteEntry({ type: 'module', remoteEntry: `${chartsRemoteEntryUrl}/remoteEntry.js` }),
    loadRemoteEntry({ type: 'module', remoteEntry: `${eventRemoteEntryUrl}/remoteEntry.js` })
  ])
  .catch(err => console.log('Error loading remote entries', err))
  .then(() => import('./bootstrap'))
  .catch(err => console.log(err))
  .then(() => import('./bootstrap'));
  
});
